import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import SaveIcon from '@material-ui/icons/Save';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Collapse } from '@material-ui/core';
import Button from '../Buttons/ActionButtons';
import { useStyles } from './styles';
import CheckboxBtn from 'components/CheckboxBtn/CheckboxBtn';
import EmptyState from '../../components/EmptyState/EmptyState';

const Accordion = (props) => {
  const {
    accordionContent,
    itemToggle,
    data,
    collapsed,
    blockData,
    selectCheckbox
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      {data?.length > 0 ? (
        data.map((it, idx) => (
          <Fragment key={idx}>
            <span
              className={clsx(classes.flex, classes.paper)}
              onClick={() => itemToggle(idx)}>
              <span>
                <span className={classes.numberWrapper}>{`${idx + 1}`}</span>
                <span
                  className={classes.textWrapper}>{`${it.category_name}`}</span>
              </span>
              <ExpandMore
                className={clsx(
                  classes.left,
                  collapsed[idx] && classes.expandLess
                )}
              />
            </span>
            <Collapse in={collapsed[idx]} timeout="auto" unmountOnExit>
              {accordionContent && accordionContent(it.winners)}
            </Collapse>
          </Fragment>
        ))
      ) : (
        <EmptyState
          className={classes.emptyState}
          emptyStateText={t('noResults')}
        />
      )}
    </>
  );
};
export default Accordion;
