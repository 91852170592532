import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import moment from 'moment';

import { Tooltip } from '@material-ui/core';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { ReactComponent as DropdownUp } from 'assets/img/new_dropdown_grey.svg';
import { ReactComponent as PencilEdit } from 'assets/img/pencil_edit_grey.svg';
import { ReactComponent as InfoMark } from 'assets/img/info_mark.svg';
import { ReactComponent as Downloads } from 'assets/img/downloads_grey.svg';
import { ReactComponent as ArrowTop } from 'assets/img/arrow_top.svg';
import { ReactComponent as EditRecordGrey } from 'assets/img/new_edit_grey.svg';
import { ReactComponent as DeleteGrey } from 'assets/img/new_trash_grey.svg';
import { ReactComponent as AssignGrey } from 'assets/img/new_assign_grey.svg';
import { ReactComponent as UnselectedCheckboxGrey } from 'assets/img/new_unselected_checkbox_grey.svg';
import { ReactComponent as SelectedCheckboxGrey } from 'assets/img/new_selected_checkbox_grey.svg';
import { ReactComponent as Clear } from 'assets/img/new_x_grey.svg';
import { ReactComponent as RestoreIc } from 'assets/img/restore-white.svg';

import {
  MODAL_EDIT_IC,
  ADD_CATEGORIES,
  EMAIL,
  serverDefaultPath,
  COACH_MODAL_EDIT_IC,
  TOURN_INFO_IC,
  DELETE_ICON,
  CLEAR_ICON,
  CATEGORY_ID,
  CATEGORY_NAME,
  SEND_FINAL_IC
} from '../../../helpers/constants';
import {
  organizerName,
  selectedValue,
  finishedRegistration
} from '../../../helpers/selectors';

import { SearchInput } from 'components';
import HeaderTournInfo from '../../HeaderTournInfo/HeaderTournInfoNewDesign';
import Button from 'components/Buttons/ActionButtons';
import Table from 'components/Table/CardsList';
import Filter from 'components/Filter/Filter';
import CheckboxBtn from 'components/CheckboxBtn/CheckboxBtn';
import ListItems from 'components/Table/ListItems';

import { useStyles, useStylesActionComponent } from './Styles';

const DOWNLOAD_FILE = 'download_files';

const QuickTournRegistration = (props) => {
  const {
    savedCoachForm,
    invitationInfo,
    tournamentData,
    participants,
    showModal,
    searchBar,
    clearSearch,
    searchText,
    pressEnter,
    isLoading,
    exportParticipants,
    exportJudges,
    viewportWidth,
    onChangeInnerPage,
    innerPageIdx, //index of the page (Participants or Judges)
    allJudges,
    collapsed,
    subItemToggle,
    filters,
    restoreParticipant,
    onToggleJudgeStatus,
    onSwitchToggleAssigned,
    selectArchive,
    sortDirection,
    onFilterRecordsBySelectedCategory,
    onSort,
    resetFilters
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const isMobile = viewportWidth < 768;
  const openRegistration = !!+tournamentData?.registration_active;
  const openParticipantRegistration =
    openRegistration &&
    !finishedRegistration(
      tournamentData?.registration_date,
      tournamentData?.finished
    );
  const openJudgeRegistration =
    openRegistration &&
    !finishedRegistration(
      tournamentData?.reg_judge_date,
      tournamentData?.finished
    );

  const onToggleFilter = () => {
    setIsFilterOpen((prev) => !prev);
  };

  const actionHeaderParams = [
    ...(!!+tournamentData?.reg_judge_form
      ? [{ val: '. .', size: 'repeat(2, minmax(5em, 10em))' }]
      : []),
    ...((
      innerPageIdx === 0 ? openParticipantRegistration : openJudgeRegistration
    )
      ? [{ val: 'addNew', size: 'minmax(5em, 15em)' }]
      : []),
    { val: 'search', size: '1fr' },
    { val: 'export', size: 'minmax(5em, 15em)' },
    { val: 'filter', size: 'minmax(5em, 15em)' },
    ...(innerPageIdx === 0 && openParticipantRegistration
      ? [{ val: 'submit', size: 'minmax(5em, 15em)' }]
      : [])
  ];

  const classes3 = useStylesActionComponent(actionHeaderParams);

  const FIRST_NAME_VAL = !+tournamentData?.last_name_first
    ? 'first_name'
    : 'last_name';

  const LAST_NAME_VAL = !+tournamentData?.last_name_first
    ? 'last_name'
    : 'first_name';

  const FIRST_NAME_JUDGE_TABLE_VAL = !+tournamentData?.last_name_first
    ? 'firstName'
    : 'lastName';

  const LAST_NAME__JUDGE_TABLE_VAL = !+tournamentData?.last_name_first
    ? 'lastName'
    : 'firstName';

  const summaryHeader = (tournType) => [
    ...(tournamentData
      ? [
          {
            title: t('eventInfo'),
            img: {
              src:
                tournamentData.poster &&
                serverDefaultPath + tournamentData.poster
            },
            button: [
              {
                className: true,
                ic: (
                  <span
                    id={TOURN_INFO_IC}
                    style={{ height: 'fit-content' }}
                    className={clsx(classes.flex, classes.onHover)}
                    onClick={(evt) => {
                      showModal(evt, null, null, null, null, TOURN_INFO_IC);
                    }}>
                    <Tooltip arrow title={t('eventInfo')}>
                      <span className={clsx(classes.flex)}>
                        <InfoMark
                          className={clsx(classes.radiusIc, classes.noEvent)}
                        />
                      </span>
                    </Tooltip>
                  </span>
                )
              },
              {
                className: true,
                ic: (
                  <span
                    id={DOWNLOAD_FILE}
                    style={{
                      height: 'fit-content'
                    }}
                    className={clsx(classes.flex, classes.onHover)}
                    onClick={(evt) => {
                      showModal(evt, null, null, null, null, DOWNLOAD_FILE);
                    }}>
                    <Tooltip
                      arrow
                      title={[t('download'), t('documents')].join(' ')}>
                      <span className={clsx(classes.flex)}>
                        <Downloads
                          className={clsx(classes.radiusIc, classes.noEvent)}
                          onClick={showModal}
                        />
                      </span>
                    </Tooltip>
                  </span>
                )
              }
            ],
            info: [
              {
                label: t('sportEvent'),
                name:
                  invitationInfo.tournament_name ||
                  tournamentData.tournament_name
              },
              {
                label: [t('tournOrganizer'), t('country')].join(' / '),
                name: [
                  [
                    tournamentData.organizer_club,
                    ', ',
                    organizerName(tournamentData)?.firstN,
                    ' ',
                    organizerName(tournamentData)?.lastN,
                    ' '
                  ],
                  ' / ',
                  tournamentData.iso3
                ]
              },
              {
                label: t('eventStart'),
                name:
                  tournamentData.start_date &&
                  moment(tournamentData.start_date).format('DD.MM.YYYY')
              }
            ]
          }
        ]
      : []),
    {
      title: t('registrarInfo'),
      img: { src: savedCoachForm.imagePreview },
      button: [
        {
          ic: (
            <span
              id={COACH_MODAL_EDIT_IC}
              style={{ height: 'fit-content' }}
              className={clsx(classes.flex, classes.onHover)}
              onClick={(evt) => {
                showModal(evt, null, null, null, null, COACH_MODAL_EDIT_IC);
              }}>
              <Tooltip arrow title={t('edit')}>
                <span className={classes.flex}>
                  <PencilEdit
                    className={clsx(classes.radiusIc, classes.noEvent)}
                  />
                </span>
              </Tooltip>
            </span>
          )
        }
      ],
      info: [
        {
          label: t('teamRepresentative'),
          name:
            savedCoachForm?.[FIRST_NAME_VAL] +
            ' ' +
            savedCoachForm?.[LAST_NAME_VAL]
        },
        {
          label: [t('club'), t('country')].join(' / '),
          name: [savedCoachForm.club, savedCoachForm.iso3].join(' / ')
        },
        { label: EMAIL, name: savedCoachForm.email }
      ]
    }
  ];

  const recordDataParams = (item, idx) => {
    return {
      main: {
        label: t('name'),
        hasSort: true,
        sort: 1,
        style: {
          wrapper: classes.marginTop1,
          cardMainPart: classes.cardValuePart
        },
        rowOrder: {
          value: idx + 1
        },
        name: [
          item?.[FIRST_NAME_VAL],
          item?.[LAST_NAME_VAL],
          ...(!!+tournamentData?.show_reg_patronymic ? [item?.patronymic] : [])
        ].join(' '),
        hasPhoto: true,
        photo: item?.photo
      },
      info: [
        { sort: 5, value: item?.age, name: t('age') },
        { sort: 4, value: item?.gender, name: t('gender') },
        { value: item?.weight, name: t('weight') },
        ...(!!+tournamentData?.height_needed
          ? [{ value: item?.height, name: t('height') }]
          : []),
        ...(!!+tournamentData?.show_reg_qdan
          ? [{ value: item.qdan_name, name: t('kyuDan') }]
          : []),
        ...(!!+tournamentData?.sport_rank_needed
          ? [{ value: item.sport_rank_short, name: t('sportsCategory') }]
          : []),
        {
          value:
            item?.categories_info?.length > 0 ? (
              <ListItems
                {...{ collapsed }}
                elem={item}
                elemParamN="categories_info"
                itemParamN="category_name"
                subElemParamN="category_id"
                paramElemName="id"
                shouldAllowEditing={true}
                styles={{ gridDropdown: classes.gridDropdownList }}
                actionIconsList={(elem, category, currentClasses) => (
                  <>
                    {openParticipantRegistration && (
                      <span
                        id={CLEAR_ICON}
                        className={clsx(classes.flex, classes.onHover)}
                        onClick={(e) => {
                          e.stopPropagation();
                          showModal(e, item, null, category);
                        }}>
                        <Clear
                          className={clsx(
                            classes.noEvent,
                            currentClasses,
                            classes.marginLeft04
                          )}
                        />
                      </span>
                    )}
                  </>
                )}
              />
            ) : (
              '-'
            ),
          list: true,
          name: (
            <>
              {t('categories')}
              {item?.categories_info?.length > 2 && (
                <span
                  className={clsx(
                    classes.marginLeft04,
                    classes.numOfCategories
                  )}>
                  {item?.categories_info?.length}
                </span>
              )}
              {item?.categories_info?.length > 2 && (
                <DropdownUp
                  className={clsx(
                    classes.flex,
                    classes.onHover,
                    classes.marginLeft04,
                    !collapsed[item.id] && classes.rotate180
                  )}
                  onClick={() => {
                    subItemToggle(item.id);
                  }}
                />
              )}
            </>
          )
        },
        ...(!+tournamentData?.no_country
          ? [{ value: item.participant_country, name: t('country') }]
          : []),
        {
          value: item.coach_name,
          name: t('coach')
        }
      ]
    };
  };

  const recordDataParams2 = (item, idx) => {
    return {
      main: {
        label: t('name'),
        hasSort: true,
        sort: 1,
        style: {
          wrapper: classes.marginTop1,
          cardMainPart: classes.cardValuePart
        },
        rowOrder: {
          value: idx + 1
        },
        name: [
          item?.[FIRST_NAME_JUDGE_TABLE_VAL],
          item?.[LAST_NAME__JUDGE_TABLE_VAL],
          ...(!!+tournamentData?.show_reg_patronymic ? [item?.patronymic] : [])
        ].join(' '),
        hasPhoto: true,
        photo: item?.photo
      },
      info: [
        { sort: 2, value: item?.gender, name: t('gender') },
        ...(!!+tournamentData?.show_reg_qdan
          ? [{ sort: 3, value: item.qdan_name, name: t('kyuDan') }]
          : []),
        ...(!!+tournamentData?.show_reg_iko
          ? [{ value: item.qualificationIkoShort, name: t('ikoQual') }]
          : []),
        { sort: 4, value: item?.experience, name: t('experience') },
        { value: item?.qualificationRfShort, name: t('rfQual') },
        { value: item?.positionName, name: t('positions') },
        { sort: 5, value: item?.status ? t('yes') : t('no'), name: t('status') }
      ]
    };
  };

  const assignedLabel =
    innerPageIdx === 0
      ? filters?.showAssignedParticipants && t('assigned')
      : filters?.showAssignedJudges && t('assigned');
  const archiveLabel =
    innerPageIdx === 0 && filters?.archive && t('showDeleted');
  const labels = [
    ...(assignedLabel ? [assignedLabel] : []),
    ...(archiveLabel ? [archiveLabel] : [])
  ];

  return (
    <>
      {savedCoachForm.id && tournamentData && (
        <HeaderTournInfo
          {...{ summaryHeader }}
          {...{ tournamentData }}
          {...{ viewportWidth }}
        />
      )}
      <span className={clsx(classes3.gridAction, classes.marginBottom1)}>
        {!!+tournamentData?.reg_judge_form && (
          <>
            <Button
              className={innerPageIdx === 0 && classes.textColor}
              onClick={() => onChangeInnerPage(0)}
              label={t('participants')}
            />

            <Button
              className={innerPageIdx === 1 && classes.textColor}
              onClick={() => onChangeInnerPage(1)}
              label={t('judges')}
            />
          </>
        )}
        {(innerPageIdx === 0
          ? openParticipantRegistration
          : openJudgeRegistration) && (
          <Button
            id={MODAL_EDIT_IC}
            isSaveBtn={true}
            onClick={(evt) => {
              showModal(evt, null, null, null, null, MODAL_EDIT_IC);
            }}
            className={clsx(classes.addNewBtn)}
            label={innerPageIdx === 0 ? t('addAthlete') : t('addJudge')}
          />
        )}
        <SearchInput
          onChange={searchText}
          id="search-input"
          onKeyPress={pressEnter}
          isSearchInactive={!searchBar}
          value={searchBar || ''}
          {...{ clearSearch }}
          className={clsx(classes.searchIpnut)}
        />

        <Button
          isSaveBtn
          onClick={innerPageIdx === 0 ? exportParticipants : exportJudges}
          className={clsx(classes.exportParticipantList)}
          label={t('participantList')}
        />
        <Button
          onClick={onToggleFilter}
          label={
            <span
              className={clsx(
                classes.centerVertically,
                classes.center,
                classes.relative,
                classes.fullWidth
              )}>
              {t('filter')}
              <ArrowDropDownIcon
                className={clsx(
                  isFilterOpen && classes.rotate180,
                  classes.absoluteRight
                )}
              />
            </span>
          }
          isSaveBtn={!isFilterOpen}
          className={clsx(isFilterOpen && classes.selected, classes.filterBtn)}
        />
        {innerPageIdx === 0 && openParticipantRegistration && (
          <Button
            isSaveBtn
            id={SEND_FINAL_IC}
            onClick={(evt) => {
              showModal(evt, null, null, null, null, SEND_FINAL_IC);
            }}
            className={clsx(classes.submitApplication)}
            label={t('sendRequest')}
          />
        )}
      </span>

      {isFilterOpen ? (
        <div className={clsx(classes.filterOptions, classes.marginBottom05)}>
          {innerPageIdx == 0 && filters?.categories && (
            <Filter
              disableClearable
              options={filters.categories}
              item={CATEGORY_NAME}
              onChange={onFilterRecordsBySelectedCategory}
              value={
                selectedValue(
                  filters.categories,
                  CATEGORY_ID,
                  filters.filteredByCategoryId,
                  true
                ) || ''
              }
              label={t('category')}
            />
          )}
          {innerPageIdx == 0 && (
            <CheckboxBtn
              checked={filters?.archive ?? false}
              onChange={selectArchive}
              label={t('showDeleted')}
              formControlClassName={classes.checkBoxBorder}
            />
          )}
          <CheckboxBtn
            checked={
              innerPageIdx === 0
                ? filters?.showAssignedParticipants
                : filters?.showAssignedJudges ?? false
            }
            onChange={onSwitchToggleAssigned}
            label={t('assigned')}
            formControlClassName={classes.checkBoxBorder}
          />
        </div>
      ) : (
        labels?.length > 0 && (
          <Button
            onClick={resetFilters}
            className={clsx(classes.flex, classes.activFilterWrapper)}
            label={
              <>
                <span
                  className={clsx(
                    classes.pointer,
                    classes.flex,
                    classes.center,
                    classes.flexGrow1
                  )}>
                  {t('filter')}: {!isMobile && labels.join(', ')}
                </span>
                <Clear className={clsx(classes.clearIc, classes.pointer)} />
              </>
            }
          />
        )
      )}

      <Table
        {...{ tournamentData, onSort, sortDirection }}
        recordDataParams={
          innerPageIdx === 0 ? recordDataParams : recordDataParams2
        }
        data={
          !isLoading &&
          savedCoachForm.id && [
            ...(innerPageIdx === 0
              ? filters?.showAssignedParticipants
                ? filters?.assignedParticipants
                : participants
              : filters?.showAssignedJudges
              ? filters?.assignedJudges
              : allJudges)
          ]
        }
        actionIcons={(item, idx, className) => {
          return (
            <>
              {(innerPageIdx === 0
                ? !filters?.archive && openParticipantRegistration
                : openJudgeRegistration) && (
                <>
                  <Tooltip
                    arrow
                    title={
                      innerPageIdx === 0
                        ? t('updateRecord', {
                            name: t('participant')
                          })
                        : t('updateJudge')
                    }>
                    <span
                      className={clsx(
                        classes.flex,
                        classes.buttonsSize,
                        classes.onHover
                      )}
                      id={MODAL_EDIT_IC}
                      onClick={(evt) => showModal(evt, item, null, null, idx)}>
                      <EditRecordGrey
                        className={clsx(
                          classes.buttonsSize,
                          classes.noEvent,
                          classes.buttonsBorder
                        )}
                      />
                    </span>
                  </Tooltip>
                  {innerPageIdx === 0 && (
                    <Tooltip arrow title={t('assignParticipantTooltip')}>
                      <span
                        className={clsx(
                          classes.flex,
                          classes.buttonsSize,
                          classes.marginLeft1,
                          classes.onHover
                        )}
                        id={ADD_CATEGORIES}
                        onClick={(evt) => showModal(evt, item)}>
                        <AssignGrey
                          className={clsx(
                            classes.buttonsSize,
                            classes.noEvent,
                            classes.buttonsBorder
                          )}
                        />
                      </span>
                    </Tooltip>
                  )}
                  {innerPageIdx !== 0 && (
                    <Tooltip
                      arrow
                      title={t(
                        item.status === '1'
                          ? 'deleteApplication'
                          : 'submitApplication'
                      )}>
                      <>
                        {!!+item.status ? (
                          <Tooltip arrow title={t('removeFromJudging')}>
                            <SelectedCheckboxGrey
                              className={clsx(
                                classes.buttonsSize,
                                classes.buttonsBorder,
                                classes.onHover,
                                classes.marginLeft1
                              )}
                              onClick={(evt) =>
                                onToggleJudgeStatus(evt, item, false)
                              }
                            />
                          </Tooltip>
                        ) : (
                          <Tooltip arrow title={t('assignToJudging')}>
                            <UnselectedCheckboxGrey
                              className={clsx(
                                classes.buttonsSize,
                                classes.buttonsBorder,
                                classes.onHover,
                                classes.marginLeft1
                              )}
                              onClick={(evt) =>
                                onToggleJudgeStatus(evt, item, true)
                              }
                            />
                          </Tooltip>
                        )}
                      </>
                    </Tooltip>
                  )}
                  <Tooltip
                    arrow
                    title={t('deleteRecord', {
                      name: t(innerPageIdx === 0 ? 'participant' : '')
                    })}>
                    <span
                      id={DELETE_ICON}
                      className={clsx(
                        classes.buttonsSize,
                        classes.onHover,
                        classes.marginLeft1
                      )}
                      onClick={(evt) => showModal(evt, item)}>
                      <DeleteGrey
                        className={clsx(
                          classes.buttonsSize,
                          classes.noEvent,
                          classes.buttonsBorder
                        )}
                      />
                    </span>
                  </Tooltip>
                </>
              )}
              {innerPageIdx === 0 && filters.archive && (
                <Tooltip arrow title={t('restore')}>
                  <RestoreIc
                    className={clsx(
                      classes.buttonsSize,
                      classes.buttonsBorder,
                      classes.onHover
                    )}
                    onClick={(evt) => restoreParticipant(evt, item)}
                  />
                </Tooltip>
              )}
            </>
          );
        }}
      />

      <ArrowTop
        id="scroll_to_top"
        onClick={() =>
          window.scroll({
            top: document.querySelector('#top').offsetTop,
            behavior: 'smooth'
          })
        }
        className={classes.scrollToTopBtn}
      />
    </>
  );
};

export default QuickTournRegistration;
