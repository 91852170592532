import { Component } from 'react';
import { withRouter } from '../../components/withRouter';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';

import { Tooltip, TextField } from '@material-ui/core';

import { ReactComponent as DeleteIcon } from 'assets/img/new_trash_grey.svg';
import { ReactComponent as EditIcon } from 'assets/img/new_edit_grey.svg';

import { ReactComponent as PublishedIcon } from '../../assets/img/published.svg';
import { ReactComponent as NoCountryIcon } from '../../assets/img/no_country.svg';
import { ReactComponent as CoachesIcon } from '../../assets/img/coaches.svg';
import { ReactComponent as TimerIcon } from '../../assets/img/timer.svg';
import { ReactComponent as BronzeFightIcon } from '../../assets/img/bronze_fight.svg';
import { ReactComponent as NoParticipantNumIcon } from '../../assets/img/no_participant_num.svg';
import { ReactComponent as CloseFlagIcon } from '../../assets/img/close_flag.svg';
import { ReactComponent as ArrowIc } from '../../assets/img/arrow_right_white_bg&grey_border.svg';

import CategoriesIcon from '@material-ui/icons/CardMembership';
import AutorenewIcon from '@material-ui/icons/Autorenew';

import { AuthContext } from '../../AuthContext';

import { ID, serverDefaultPath, DELETE_ICON } from '../../helpers/constants';
import {
  fetchTournaments,
  deleteTournament,
  changeModal,
  pageChangeHandler,
  rowsPerPageHandler,
  fetchReferenceRegion,
  textChangeHandler,
  selectOption,
  fetchReference
} from '../../helpers/util';
import {
  selectedValue,
  DDMMYYYY,
  isDesktopView,
  generate6DigitsCode,
  finishedTournament
} from '../../helpers/selectors';
import { changeTitle } from '../../helpers/actions';

import LoadingState from '../../components/LoadingState/LoadingState';
import SideModal from '../../components/Snackbar/SideModal';
import _ from 'lodash';

import Table from '../../components/Table/CardsList';
import Filter from '../../components/Filter/Filter';
import Button from '../../components/Buttons/ActionButtons';
import { SearchInput } from 'components';
import Modal from '../../components/Modal/ModalNewDesign';
import AddTournament from '../AddTournament/AddTournament';
import Pagination from '../../components/TablePagination/TablePagination';
import CheckboxBtn from 'components/CheckboxBtn/CheckboxBtn';

import styles from './Styles';
import EmptyState from 'components/EmptyState/EmptyState';

const EDIT_TOURNAMENT_ICON = 'EDIT_TOURNAMENT_ICON';

class Tournaments extends Component {
  state = {
    tournamentsList: [],
    showModal: false,
    shouldShowForm: false,
    shouldUpdateTable: false,
    success: false,
    loading: true,
    langOnLoad: localStorage.getItem('i18nextLng'),
    open: false,
    tournamentId: null,
    filteredTournaments: [],
    sortingFilters: [],
    tournamentsCount: 0,
    allRegions: [],
    filters: {
      searchBar: ''
    },
    rowsPerPage:
      (localStorage.getItem('rowsPerPage') &&
        +localStorage.getItem('rowsPerPage')) ||
      25,
    page: 1
  };

  fetchTournaments = fetchTournaments.bind(this);
  deleteTournament = deleteTournament.bind(this);
  textChangeHandler = textChangeHandler.bind(this);
  changeModal = changeModal.bind(this);
  pageChangeHandler = pageChangeHandler.bind(this);
  rowsPerPageHandler = rowsPerPageHandler.bind(this);
  fetchReferenceRegion = fetchReferenceRegion.bind(this);
  selectOption = selectOption.bind(this);
  fetchReference = fetchReference.bind(this);

  static contextType = AuthContext;

  componentDidUpdate(prevProps, prevState) {
    const { shouldUpdateTable, searchBar, langOnLoad } = this.state;
    const { t } = this.props;
    const currentLang = localStorage.getItem('i18nextLng');

    if (
      shouldUpdateTable &&
      shouldUpdateTable !== prevState.shouldUpdateTable
    ) {
      this.fetchTournaments(
        null,
        null,
        searchBar,
        () => this.filterTournaments(),
        true
      );
    }

    if (langOnLoad !== currentLang) {
      changeTitle(t('event'));

      this.setState({ langOnLoad: currentLang });
    }
  }

  filterTournaments = () => {
    const { tournamentsList, filters } = this.state;
    const { searchBar, tournamentStatus } = filters;

    let filteredTournaments = tournamentsList.filter((tournament) => {
      const isDefinitelyColsed = finishedTournament(tournament);
      const nameMatches = tournament.tournament_name
        .toLowerCase()
        .includes(searchBar.toLowerCase().trim());
      const typeMatches =
        tournamentStatus === 2
          ? isDefinitelyColsed
          : tournamentStatus === 1
          ? !isDefinitelyColsed
          : true;

      return nameMatches && typeMatches;
    });

    this.setState({ filteredTournaments });
  };

  handleFilterChange = (event, value) => {
    const statusId = value && value.id ? value.id : null;

    ti;

    this.setState(
      (prevState) => ({
        filters: { ...prevState.filters, tournamentStatus: statusId }
      }),
      () => this.filterTournaments()
    );
  };

  componentDidMount() {
    const { langOnLoad } = this.state;
    const { role } = this.context.authState;
    this.fetchTournaments(null, null, null, () =>
      this.setState({ loading: false })
    );

    changeTitle(this.props.t('event'));

    // this.fetchReference(
    //   4113,
    //   (data) => {
    //     const filteredData = data.filter((item) => item.name === 'events');
    //     this.setState({ iframeURL: filteredData[0] });
    //   },
    //   null,
    //   langOnLoad
    // );

    role === 'administrator' && this.setState({ type: 1 });
  }

  selectType = (event, value) => {
    const { searchBar } = this.state;
    if (value === null) {
      this.setState({ type: value });
      this.fetchTournaments(null, value, searchBar, null, true);
    } else {
      this.setState({ type: value.id });
      this.fetchTournaments(null, value.id, searchBar, null, true);
    }
  };

  rowClickedHandler = (evt, item) => {
    evt.preventDefault();

    const { navigate, location } = this.props;

    navigate('/event/' + item.id, {
      state: { prevUrl: location.pathname }
    });
  };

  searchHandler = (event) => {
    const { value } = event.target;
    const { tournamentsList } = this.state;
    this.setState(
      {
        searchBar: value,
        page: 1,
        filteredTournaments: tournamentsList.filter((tournament) => {
          return tournament.tournament_name
            .toLowerCase()
            .includes(value.toLowerCase().trim());
        })
      },
      () =>
        this.setState({
          tournamentsCount: this.state.filteredTournaments.length
        })
    );
  };

  orderTournamentsByColumn = (columnName) => {
    const { sortingFilters, filteredTournaments } = this.state;
    const sortingFiltersExisting = sortingFilters.indexOf(columnName);
    if (sortingFiltersExisting !== -1) {
      sortingFilters.splice(sortingFiltersExisting, 1);
    } else {
      sortingFilters.push(columnName);
    }
    const sortedFilteredTournament = _.orderBy(
      filteredTournaments,
      [...sortingFilters],
      ['asc']
    );
    this.setState({
      sortingFilters,
      filteredTournaments: sortedFilteredTournament
    });
  };

  showModal = (evt, item) => {
    const targetID = evt.currentTarget.id;
    const id = item?.id;
    let tournamentData = {};

    if (id) {
      this.fetchReferenceRegion(item?.c_id);
      tournamentData = {
        ...item,
        ...(item.poster
          ? { imagePreview: serverDefaultPath + item.poster }
          : {}),
        country_id: item.c_id,
        date: item.start_date,
        tour_time: item.start_time,
        tournament_id: item.id,
        id: item.id,
        targetID
      };
    }

    this.setState({
      tournamentId: id,
      open: true,
      tournamentData,
      selectedRow: id
    });
  };

  triggerTableUpdate = () => this.setState({ shouldUpdateTable: true });

  hideModal = () => this.setState({ open: false });

  closeModalHandler = () => this.setState({ showModal: false });

  clearSearch = () => {
    const { tournamentsList } = this.state;
    const len = tournamentsList?.length;

    this.setState({
      searchBar: '',
      filteredTournaments: tournamentsList,
      tournamentsCount: len
    });
  };

  onDelete = (data, action) => {
    const { tournamentData } = this.state;

    this.deleteTournament(
      {
        tournament_id: tournamentData?.id,
        ...(data ?? {})
      },
      (msg) => {
        action && action(msg);
      }
    );
  };

  onConfirmDelete = () => {
    const { tournamentData } = this.state;

    const callback = () => {
      this.triggerTableUpdate();
      this.hideModal();
    };

    if (tournamentData?.force) {
      this.onDelete({ force: true, shouldGetHash: true }, (msg) => {
        this.onDelete({ ...(msg ?? {}) }, () => {
          callback();
        });
      });
    } else {
      this.onDelete({}, () => {
        callback();
      });
    }
  };

  onToggleForce = (evt) => {
    const { tournamentData } = this.state;
    const { checked } = evt.target;
    let cpy = { ...tournamentData };

    cpy = {
      ...cpy,
      force: checked,
      random: checked ? generate6DigitsCode() : null,
      hash: checked ? cpy.hash : null
    };

    this.setState({ tournamentData: cpy });
  };

  render() {
    const {
      showModal,
      success,
      modalInfo,
      filteredTournaments,
      open,
      loading,
      searchBar,
      selectedRow,
      tournamentsCount,
      page,
      rowsPerPage,
      tournamentData,
      filters
    } = this.state;
    const { t, classes } = this.props;
    const { viewportWidth, authState } = this.context;
    const { role } = authState;
    const hasDesktopSize = isDesktopView(viewportWidth);

    const TOURNAMENT_STATUS = [
      { id: 1, name: t('upcoming') },
      { id: 2, name: t('finished') }
    ];

    const recordDataParams = (item, idx) => {
      const updateDate = item?.update_date?.split(' ');

      return {
        main: {
          style: {
            wrapper: classes.marginTop1,
            cardMainPart: classes.cardValuePart
          },
          rowOrder: { value: (page - 1) * rowsPerPage + idx + 1 },
          name: item?.tournament_name,
          hasPhoto: true,
          photo: item?.poster
        },
        info: [
          {
            value: item?.id,
            name: t('code')
          },
          ...(role === 'administrator'
            ? [{ value: 'organizer', name: t('organizer') }]
            : []),
          {
            value:
              +item?.type === 1
                ? t('regionalEvent')
                : +item?.type === 2
                ? t('internationalEvent')
                : t('trainingSchool'),
            name: t('eventType')
          },
          { value: item?.participants_count, name: t('participantsNum') },
          { value: DDMMYYYY(item?.start_date), name: t('date') },

          ...(role === 'administrator'
            ? [
                {
                  value: DDMMYYYY(item?.registration_date),
                  name: `Reg. ${t('date')}`
                }
              ]
            : []),
          ...(role === 'master'
            ? [{ value: item?.start_time, name: t('time') }]
            : []),
          {
            value:
              finishedTournament(item) || !!+tournamentData?.finished
                ? t('eventClosed')
                : !+item?.registration_active
                ? t('registrationClosed')
                : t('registrationOpened'),
            name: t('eventStatus')
          },
          { value: item?.iso3, name: t('country') },
          { value: item?.region, name: t('region') },
          { value: item?.city, name: t('city') },
          ...(role === 'administrator'
            ? [{ value: item?.published, name: <PublishedIcon /> }]
            : []),
          ...(role === 'administrator'
            ? [{ value: item?.finished, name: <CloseFlagIcon /> }]
            : []),
          ...(role === 'administrator'
            ? [{ value: item?.bronze_fight_enabled, name: <BronzeFightIcon /> }]
            : []),
          ...(role === 'administrator'
            ? [
                {
                  value: item?.no_participant_number,
                  name: <NoParticipantNumIcon />
                }
              ]
            : []),
          ...(role === 'administrator'
            ? [{ value: item?.no_country, name: <NoCountryIcon /> }]
            : []),
          ...(role === 'administrator'
            ? [{ value: item?.active, name: <TimerIcon /> }]
            : []),
          ...(role === 'administrator'
            ? [
                {
                  value:
                    updateDate && `${DDMMYYYY(updateDate[0])} ${updateDate[1]}`,
                  name: <AutorenewIcon />
                }
              ]
            : []),
          ...(role === 'administrator'
            ? [{ value: item?.categories_count, name: <CategoriesIcon /> }]
            : []),
          ...(role === 'administrator'
            ? [{ value: item?.coaches_count, name: <CoachesIcon /> }]
            : []),
          ...(role === 'master'
            ? [{ value: item?.address, name: t('address') }]
            : [])
        ]
      };
    };

    let dialogTitle,
      subHeader,
      buttonPurpose,
      dialogContent,
      onClick,
      specificDetails;

    if (tournamentData?.targetID === DELETE_ICON) {
      subHeader = tournamentData?.id && (
        <span className={clsx(classes.flex, classes.marginTop1)}>
          {tournamentData.tournament_name}
        </span>
      );
      dialogTitle = t('deleteRecord', { name: t('event') });
      dialogContent =
        role !== 'administrator' ? (
          t('deleteEventMsg')
        ) : (
          <div style={{ display: 'grid', gap: '1rem' }}>
            {t('deleteEventMsg')}
            <CheckboxBtn
              checked={tournamentData?.force ?? false}
              onChange={this.onToggleForce}
              label={t('deletePermanently')}
            />
            {tournamentData?.force && tournamentData?.random && (
              <>
                {t('confirm2deletePermanently', {
                  name: tournamentData?.random
                })}
                <TextField
                  className={classes.txtField}
                  name="confirmDigits"
                  value={tournamentData?.confirmDigits || ''}
                  onChange={(e) => this.textChangeHandler(e, 'tournamentData')}
                  variant="outlined"
                />
              </>
            )}
          </div>
        );
      buttonPurpose = t('deleteRecord', { name: '' });
      onClick = this.onConfirmDelete;
      specificDetails = {
        disabled:
          role === 'administrator' &&
          tournamentData?.force &&
          (!tournamentData?.confirmDigits ||
            tournamentData?.confirmDigits !== tournamentData.random)
      };
    } else {
      dialogTitle = tournamentData?.id
        ? t('updateRecord', { name: t('event') })
        : t('addRecord', { name: t('event') });
      dialogContent = (
        <AddTournament
          state={this.state}
          triggerTableUpdate={this.triggerTableUpdate}
        />
      );
    }

    return (
      <>
        <SideModal
          closeModal={this.closeModalHandler}
          success={success}
          show={showModal}
          info={modalInfo}
        />
        <Modal
          {...{ open }}
          close={this.hideModal}
          {...{ dialogTitle, dialogContent, subHeader, onClick, buttonPurpose }}
          specificDetails={{
            ...(specificDetails ?? {}),
            className: {
              ...(hasDesktopSize &&
                (tournamentData?.targetID === DELETE_ICON
                  ? { paper: classes.modalWidth }
                  : { paper: classes.modalWidthViewEvent }))
            }
          }}
        />
        {loading ? (
          <LoadingState />
        ) : (
          <>
            <div className={classes.grid}>
              <SearchInput
                className={classes.gridSearchArea}
                onChange={this.searchHandler}
                value={searchBar || ''}
                clearSearch={this.clearSearch}
              />
              <Button
                className={classes.gridButtonArea}
                id="add_new_button"
                onClick={this.showModal}
                isSaveBtn={true}
                label={
                  <>
                    <span className={classes.plus}>&#x002B;</span>
                    {t('addNew')}
                  </>
                }
              />
              <Filter
                classes={{
                  root: classes.outlined
                }}
                className={classes.gridFilterArea}
                disableClearable
                options={TOURNAMENT_STATUS}
                onChange={(e, val) => {
                  this.selectOption(
                    e,
                    val,
                    'filters',
                    'tournamentStatus',
                    ID,
                    null,
                    this.filterTournaments
                  );
                }}
                label={t('event')}
                variant={'outlined'}
                item={'name'}
                value={
                  selectedValue(
                    TOURNAMENT_STATUS,
                    ID,
                    filters.tournamentStatus
                  ) || ''
                }
              />
            </div>
            {filteredTournaments.length > 0 ? (
              <>
                <Table
                  {...{ recordDataParams }}
                  data={filteredTournaments.slice(
                    (page - 1) * rowsPerPage,
                    (page - 1) * rowsPerPage + rowsPerPage
                  )}
                  // onClick={(evt, item) => this.rowClickedHandler(evt, item)}
                  // shouldAllowEditing={() => true}
                  actionIcons={(
                    item,
                    _,
                    className,
                    iconWrapper,
                    marginLeft,
                    marginBottom
                  ) => (
                    <>
                      {!(
                        role === 'master' &&
                        (item.finished === '1' || !+item.is_master)
                      ) && (
                        <>
                          <Tooltip
                            title={t('updateRecord', { name: t('event') })}>
                            <EditIcon
                              id={EDIT_TOURNAMENT_ICON}
                              className={clsx(className)}
                              onClick={(evt) => this.showModal(evt, item)}
                            />
                          </Tooltip>
                          <Tooltip
                            title={t('deleteRecord', { name: t('event') })}>
                            <DeleteIcon
                              id={DELETE_ICON}
                              className={clsx(className, classes.marginLeft1)}
                              onClick={(evt) => this.showModal(evt, item)}
                            />
                          </Tooltip>
                        </>
                      )}
                      <Tooltip title={t('event')}>
                        <ArrowIc
                          className={clsx(
                            className,
                            !(
                              role === 'master' &&
                              (item.finished === '1' || !+item.is_master)
                            ) && classes.marginLeft1
                          )}
                          onClick={(evt) => this.rowClickedHandler(evt, item)}
                        />
                      </Tooltip>
                    </>
                  )}
                  // selected={(item) => selectedRow === item.id}
                />
                <Pagination
                  count={tournamentsCount}
                  onPageChange={this.pageChangeHandler}
                  onRowsPerPageChange={this.rowsPerPageHandler}
                  {...{ page }}
                  {...{ rowsPerPage }}
                />
              </>
            ) : (
              <EmptyState
                className={classes.emptyState}
                emptyStateText={t('noResults')}
              />
            )}
          </>
        )}
      </>
    );
  }
}
export default withTranslation()(withStyles(styles)(withRouter(Tournaments)));
