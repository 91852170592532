const styles = () => ({
  marginTop1: {
    marginTop: '1rem'
  },

  cardMainPart: {
    backgroundColor: '#AEBBD0 !important',
    color: '#262441 !important'
  },
  cardValuePart: {
    wordBreak: 'break-all'
  },

  marginLeft1: {
    marginLeft: '1rem'
  },

  styleFilter: {
    width: '11em',
    marginRight: '0.7rem',
    height: 'calc(100% + 0.4rem)',
    border: 'none',
    '& .MuiInput-underline:before': {
      height: '100%',
      borderBottom: 'none',
      borderRight: '1px solid rgba(63,63,68,0.15)'
    },
    '& .MuiFormControl-root, & .MuiInputBase-root': {
      height: '100%'
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      border: 'none'
    }
  }
});

export default styles;
