import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Autocomplete } from '@material-ui/lab';

import { TextField, Checkbox } from '@material-ui/core';
import Aux from '../../hoc/Auxiliar/Auxiliar';
import { useStyles } from './StylesFilter';

const Filter = (props) => {
  const {
    className,
    options,
    onChange,
    label,
    item,
    optional,
    value,
    variant,
    disabled,
    freeSolo,
    required,
    render,
    multiple,
    disableCloseOnSelect,
    classes,
    style,
    id,
    disableClearable,
    helperText,
    shouldLookLikeBtn,
    error,
    placeholder,
    inputLabelProps,
    inputProps,
    additionalPaperComponent,
    langListbox,
    multiline,
    specificDetails,
    focused
  } = props;

  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <Autocomplete
      {...{ id }}
      {...{ style }}
      disableClearable={!disableClearable}
      renderOption={(option, { selected }) => {
        let labelOption;

        if (option?.[optional] !== 'NOT SET') {
          labelOption = [
            typeof item !== 'object'
              ? option[item]
              : [
                  item.parathesis
                    ? `(${option[item.firstName]})`
                    : option[item.firstName],
                  option[item.lastName]
                ].join(' '),
            option[optional] ? ' - ' : '',
            option[optional]
          ].join(' ');
        } else {
          labelOption =
            typeof item !== 'object'
              ? option[item]
              : [
                  item.parathesis
                    ? `(${option[item.firstName]})`
                    : option[item.firstName],
                  option[item.lastName]
                ].join(' ');
        }
        return (
          <Aux>
            {render && <Checkbox checked={selected} />}
            {labelOption}
          </Aux>
        );
      }}
      classes={{
        tag: styles.tag,
        ...(langListbox ? { option: styles.langListbox } : {}),
        ...(additionalPaperComponent && options.length === 0
          ? { listbox: styles.hideOption }
          : {}),
        ...classes
      }}
      {...{ multiple }}
      className={clsx(
        className,
        !shouldLookLikeBtn ? styles.icons : shouldLookLikeBtn
      )}
      noOptionsText={t('noOptionsText')}
      openText={t('openText')}
      clearText={t('clearText')}
      options={
        additionalPaperComponent && options.length === 0 ? [{}] : options
      }
      {...{ disableCloseOnSelect }}
      getOptionLabel={(option) => {
        const isTrue = option
          ? typeof item !== 'object'
            ? option[item]
            : option[item.firstName] || option[item.lastName]
          : '';

        return isTrue
          ? option?.[optional]
            ? option[optional] !== 'NOT SET'
              ? [
                  typeof item !== 'object'
                    ? option[item]
                    : [
                        item.parathesis
                          ? `(${option[item.firstName]})`
                          : option[item.firstName],
                        option[item.lastName]
                      ].join(' '),
                  option[optional] ? ' - ' : '',
                  option[optional]
                ].join(' ')
              : typeof item !== 'object'
              ? option[item]
              : [
                  item.parathesis
                    ? `(${option[item.firstName]})`
                    : option[item.firstName],
                  option[item.lastName]
                ].join(' ')
            : typeof item !== 'object'
            ? option[item]
            : [
                item.parathesis
                  ? `(${option[item.firstName]})`
                  : option[item.firstName],
                option[item.lastName]
              ].join(' ')
          : '';
      }}
      getOptionSelected={(option, value) =>
        typeof item !== 'object'
          ? option[item] === value[item]
          : option[item.firstName] === value[item.firstName] &&
            option[item.lastName] === value[item.lastName]
      }
      {...{ value }}
      {...{ onChange }}
      {...{ disabled }}
      {...{ freeSolo }}
      renderInput={(params) => (
        <TextField
          {...{ placeholder, multiline }}
          {...params}
          {...{ label }}
          {...{ variant }}
          {...{ required }}
          {...{ helperText }}
          {...{ error }}
          className={styles.placeholder}
          fullWidth
          InputProps={{
            ...params.InputProps,
            ...inputProps,
            ...specificDetails?.InputProps
          }}
          InputLabelProps={{
            style: {
              color: error ? '#e53935' : '#001D3D'
            },
            shrink: true
          }}
          focused={focused}
        />
      )}
    />
  );
};

export default Filter;
