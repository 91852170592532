import { Component } from 'react';
import clsx from 'clsx';
import { withRouter } from '../../components/withRouter';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';

import { AuthContext } from 'AuthContext';

import TournamentForm from '../../components/AddTournament/TournamentForm';
import SideModal from '../../components/Snackbar/SideModal';

import {
  selectedValue,
  isDesktopView,
  DDMMYYYY,
  categoryTypesPresentOnTournament,
  formatDate
} from '../../helpers/selectors';
import {
  TOURN_BANNER,
  ENABLED,
  serverDefaultPath,
  TYPE,
  ID,
  KEEP_EDITING,
  CLOSE_DISCARD,
  DISABLED
} from '../../helpers/constants';
import { changeTitle } from 'helpers/actions';
import {
  fetchCountries,
  selectOption,
  textChangeHandler,
  fetchTournaments,
  addTournament,
  updateTournament,
  changeModal,
  changeImage,
  validateForm,
  closeTournament,
  removeTBK,
  fetchAllCategoryTypes,
  getTatamisTheme,
  fetchReferenceRegion,
  importSettings,
  fetchReference,
  randomPosterTournament,
  changeTatamisName,
  fetchUsers
} from '../../helpers/util';
import LoadingState from '../../components/LoadingState/LoadingState';
import Modal from '../../components/Modal/ModalNewDesign';
import Filter from '../../components/Filter/Filter';
import RadioBtn from 'components/RadioBtn/RadioBtn';
import styles from './Styles';

const getNextSaturdayDate = () => {
  const today = new Date();
  const daysUntilSaturday = (6 - today.getDay() + 7) % 7;
  const nextSaturday = new Date(today);
  nextSaturday.setDate(today.getDate() + daysUntilSaturday);

  return formatDate(nextSaturday);
};

const getCloseDate = () => {
  const nextSaturday = getNextSaturdayDate();
  const closeDate = new Date(nextSaturday);
  closeDate.setDate(closeDate.getDate() - 1);

  return formatDate(closeDate);
};

const defaultInputs = {
  type: '1',
  country_id: '',
  city: '',
  date: getNextSaturdayDate(),
  tour_time: '9:00',
  duration_days: '1',

  registration_date: getCloseDate(),
  reg_judge_date: getCloseDate(),
  reg_date: getCloseDate(),
  reg_time: '9:00',

  calc_age_date: getNextSaturdayDate(),

  places_number: '3',
  kata_final: '6',

  scoreboard_theme: '4',
  participant_up_color: 'white',
  participant_down_color: 'red',
  tatami_set: 'en',

  tournament_name: '',
  address: ''
};

class AddTournament extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countries: [],
      tournamentData: {
        ...(props.state ? props.state.tournamentData : { ...defaultInputs })
      },
      langOnLoad: localStorage.getItem('i18nextLng'), //need this to update the list of colors based on the selected language
      errors: {},
      success: false,
      showModal: false,
      isSending: false,
      selectedCheckboxes: [
        'last_name_first',
        'no_participant_number',
        'published',
        'use_notification_win',
        'show_reg_qdan',
        'no_country',
        'show_reg_region',
        'show_reg_city',
        'show_reg_club',
        'bronze_fight_enabled'
      ],
      userForm: [],
      isLoading: true,
      open: false,
      selectedOption: {},
      selectedOptionErrors: {},
      categoryTypes: [],
      tatamisTheme: [],
      allRegions: [...(props?.state?.allRegions ?? [])],
      tournamentToImportSettings: {},
      importTourID: ''
    };

    this.tournamentParamId = props.match.params?.id;

    this.fetchCountries = fetchCountries.bind(this);
    this.selectOption = selectOption.bind(this);
    this.textChangeHandler = textChangeHandler.bind(this);
    this.fetchTournaments = fetchTournaments.bind(this);
    this.addTournament = addTournament.bind(this);
    this.updateTournament = updateTournament.bind(this);
    this.changeModal = changeModal.bind(this);
    this.changeImage = changeImage.bind(this);
    this.validateForm = validateForm.bind(this);
    this.closeTournament = closeTournament.bind(this);
    this.removeTBK = removeTBK.bind(this);
    this.fetchAllCategoryTypes = fetchAllCategoryTypes.bind(this);
    this.getTatamisTheme = getTatamisTheme.bind(this);
    this.fetchReferenceRegion = fetchReferenceRegion.bind(this);
    this.importSettings = importSettings.bind(this);
    this.fetchReference = fetchReference.bind(this);
    this.randomPosterTournament = randomPosterTournament.bind(this);
    this.changeTatamisName = changeTatamisName.bind(this);
    this.fetchUsers = fetchUsers.bind(this);
  }

  static contextType = AuthContext;

  componentDidUpdate(prevProps, prevState) {
    const { selectedOption, isEditing, langOnLoad } = this.state;
    const { t } = this.props;
    const currentLang = localStorage.getItem('i18nextLng');
    const { state } = this.props;
    const { tournamentData } = this.state;
    const { tournamentData: prevTournamentData } = prevState;

    if (state && state.tournamentData !== prevProps.state.tournamentData) {
      this.setState({
        tournamentData: { ...(state.tournamentId ? state.tournamentData : {}) },
        errors: state.tournamentId ? {} : { ...defaultInputs }
      });
    }

    if (state?.allRegions !== prevProps.state?.allRegions) {
      this.setState({
        allRegions: state.allRegions
      });
    }

    if (tournamentData.country_id !== prevTournamentData.country_id) {
      this.setState(
        (prevState) => ({
          tournamentData: {
            ...prevState.tournamentData,
            region: !prevTournamentData.country_id
              ? prevTournamentData.region
              : ''
          }
        }),
        () => {
          if (
            this.state.tournamentData.country_id !==
            prevTournamentData.country_id
          ) {
            this.fetchReferenceRegion(this.state.tournamentData.country_id);
          }
        }
      );
    }

    if (langOnLoad !== currentLang) {
      this.getTatamisTheme();
      this.setState({ langOnLoad: currentLang });

      changeTitle(t('updateRecord', { name: t('event') }));
    }

    if (
      state &&
      state.tournamentId &&
      state.tournamentId !== prevProps.state.tournamentId
    ) {
      this.fetchTournData(state.tournamentId);
    }

    if (
      isEditing &&
      selectedOption &&
      selectedOption !== prevState.selectedOption
    ) {
      this.setState({ isAttemptingToEditModalFields: true });
    }
  }

  componentDidMount() {
    const { state, t, location } = this.props;
    const { langOnLoad } = this.state;

    this.fetchReference(
      4113,
      (data) => {
        const filteredData = data.filter((item) => item.name === 'edit');
      },
      null,
      langOnLoad
    );

    this.fetchCountries();
    this.getTatamisTheme();
    this.fetchUsers(localStorage.getItem('user_id'), 'userForm', (userData) => {
      const {
        country_id,
        region,
        city,
        first_name,
        last_name,
        patronymic,
        phone
      } = userData;

      this.setState((prevState) => ({
        tournamentData: {
          ...prevState.tournamentData,
          country_id: country_id || '',
          region: region || '',
          city: city || '',
          chairman: `${last_name || ''} ${first_name || ''} ${
            patronymic || ''
          }`,
          org_phone: phone || ''
        }
      }));
    });

    if (this.tournamentParamId || (state && state.tournamentId)) {
      this.fetchTournData(
        this.tournamentParamId ? this.tournamentParamId : state.tournamentId,
        true
      );
    } else {
      this.randomPosterTournament((data) => {
        this.setState((prev) => ({
          tournamentData: {
            ...prev.tournamentData,
            param_template_name: data?.name,
            imagePreview: serverDefaultPath + data?.path
          }
        }));
      });
    }

    if ((state && !state.tournamentId) || location.pathname === '/event/add') {
      this.setState({ isLoading: false });
    }

    !state && changeTitle(t('updateRecord', { name: t('event') }));
  }

  fetchTournData = (tournamentId, hasCountrySelected) => {
    const permissionVal = (tournament) => [
      ...(tournament?.published === ENABLED ? ['published'] : []),
      ...(tournament?.bronze_fight_enabled === ENABLED
        ? ['bronze_fight_enabled']
        : []),
      ...(tournament?.no_participant_number === ENABLED
        ? ['no_participant_number']
        : []),
      ...(tournament?.no_country === ENABLED ? ['no_country'] : []),
      ...(tournament?.circle_round === ENABLED ? ['circle_round'] : []),
      ...(tournament?.reg_judge_form === ENABLED ? ['reg_judge_form'] : []),
      ...(tournament?.last_name_first === ENABLED ? ['last_name_first'] : []),
      ...(!+tournament?.registration_active ? ['registration_active'] : []),
      ...(!!+tournament?.section_import_on ? ['section_import_on'] : []),
      ...(!!+tournament?.limited_reg_on ? ['limited_reg_on'] : []),
      ...(!!+tournament?.finished ? ['is_finished'] : []),
      ...(tournament?.tameshiwari > 0 ? ['tameshiwari'] : []),
      ...(tournament?.height_needed === ENABLED ? ['height_needed'] : []),
      ...(tournament?.sport_rank_needed === ENABLED
        ? ['sport_rank_needed']
        : []),
      ...(!!+tournament?.show_reg_coach_2 ? ['show_reg_coach_2'] : []),
      ...(tournament?.use_alt_category_name === ENABLED
        ? ['use_alt_category_name']
        : []),
      ...(tournament?.use_qr === ENABLED ? ['use_qr'] : []),
      ...(tournament?.use_notification_win === ENABLED
        ? ['use_notification_win']
        : []),
      ...(tournament?.report_head === DISABLED ? ['report_head'] : []), //Disable Report Header on Reports page
      ...(tournament?.use_rep_stamp === ENABLED ? ['use_rep_stamp'] : []),
      ...(tournament?.use_rep_signature === ENABLED
        ? ['use_rep_signature']
        : []),
      ...(tournament?.show_reg_iko === ENABLED ? ['show_reg_iko'] : []),
      ...(!!+tournament?.show_reg_region ? ['show_reg_region'] : []),
      ...(!!+tournament?.show_reg_city ? ['show_reg_city'] : []),
      ...(!!+tournament?.show_reg_club ? ['show_reg_club'] : []),
      ...(tournament?.show_reg_qdan === ENABLED ? ['show_reg_qdan'] : []),
      ...(tournament?.show_reg_patronymic === ENABLED
        ? ['show_reg_patronymic']
        : []),
      ...(tournament?.use_reg_online === ENABLED ? ['use_reg_online'] : [])
    ];

    if (this.tournamentParamId) {
      this.fetchTournaments(null, null, null, null, true, 1);
      this.fetchTournaments(tournamentId, null, null, () => {
        const { tournamentData } = this.state;
        const checkedValues = permissionVal(tournamentData);

        this.setState((prevState) => ({
          tournamentData: {
            ...prevState.tournamentData,
            ...(tournamentData?.poster
              ? {
                  imagePreview: serverDefaultPath + tournamentData.poster
                }
              : {}),
            country_id: tournamentData?.c_id,
            date: tournamentData?.start_date,
            tour_time: tournamentData?.start_time,
            tournament_id: tournamentData?.id
          },
          selectedCheckboxes: checkedValues,
          errors: {},
          isLoading: false
        }));
      });
    } else {
      const checkedValues = permissionVal(this.state.tournamentData);

      this.setState({
        isLoading: false,
        selectedCheckboxes: checkedValues,
        errors: {}
      });
    }
  };

  formatDate = (date) => {
    const selectedYear = date.getFullYear();
    const selectedMonth = (date.getMonth() + 1).toString().padStart(2, '0');
    const selectedDay = date.getDate().toString().padStart(2, '0');
    return `${selectedYear}-${selectedMonth}-${selectedDay}`;
  };

  fetchTournamentData = () => {
    const { tournamentData } = this.state;

    this.setState((prevState) => ({
      tournamentData: {
        ...prevState.tournamentData,
        ...(tournamentData.poster
          ? {
              imagePreview: serverDefaultPath + tournamentData.poster
            }
          : {}),
        country_id: tournamentData.c_id,
        date: tournamentData.start_date,
        tour_time: tournamentData.start_time,
        tournament_id: tournamentData.id
      }
    }));
  };

  tournamentDateChangeHandler = (date) => {
    const { errors, tournamentData } = this.state;
    const { t } = this.props;
    const copyErrors = { ...errors };
    let cpyData = { ...tournamentData };
    const now = new Date().setHours(0, 0, 0, 0);

    if (date === null) {
      copyErrors.date = t('required');
    } else if (isNaN(Date.parse(date))) {
      copyErrors.date = t('invalidDate');
    } else if (date < now) {
      copyErrors.date = t('futureDate');
    } else {
      if (
        ['registration_date', 'reg_judge_date', 'reg_date'].some(
          (it) =>
            new Date(date) < new Date(tournamentData?.[it]).setHours(0, 0, 0, 0)
        )
      ) {
        copyErrors.date = t('futureDate');
      } else {
        delete copyErrors.date;
      }

      cpyData = { ...cpyData, date: this.formatDate(date) };
    }

    this.setState({
      tournamentData: cpyData,
      errors: copyErrors
    });
  };

  tournamentTimeChangeHandler = (date, time, param) => {
    const { errors } = this.state;
    const { t } = this.props;
    let tourTime = time;
    let cpyErrors = { ...errors };

    if (date === null) {
      cpyErrors[param] = t('required');
    } else if (isNaN(date)) {
      cpyErrors[param] = t('invalidTime');
    } else {
      delete cpyErrors[param];
    }

    this.setState((prevState) => ({
      tournamentData: {
        ...prevState.tournamentData,
        [param]: tourTime
      },
      errors: cpyErrors
    }));
  };

  registrationDateChangeHandler = (date, param) => {
    const { errors, tournamentData } = this.state;
    const { t } = this.props;
    let cpyErrors = { ...errors };
    let cpyForm = { ...tournamentData };

    if (date === null) {
      cpyErrors[param] = t('required');
    } else if (isNaN(Date.parse(date))) {
      cpyErrors[param] = t('invalidDate');
    } else {
      const newCalcDate = new Date(tournamentData.date);
      newCalcDate.setDate(
        newCalcDate.getDate() + (+tournamentData?.duration_days ?? 0) - 1
      );

      if (date > newCalcDate) {
        cpyErrors.registration_date = t('registrationDateError');
      } else {
        delete cpyErrors[param];
      }

      cpyForm = { ...cpyForm, [param]: this.formatDate(date) };
    }

    this.setState({
      tournamentData: cpyForm,
      errors: cpyErrors
    });
  };

  closeModalHandler = () => this.setState({ showModal: false });

  selectCheckbox = (key) => {
    const { selectedCheckboxes } = this.state;
    const checkedValues = selectedCheckboxes.includes(key)
      ? selectedCheckboxes.filter((it) => it !== key)
      : [...selectedCheckboxes, key];

    this.setState({ selectedCheckboxes: checkedValues });
  };

  showModal = (evt) => {
    const { tournamentData } = this.state;
    const { t } = this.props;
    const { category_types } = tournamentData;
    const targetID = evt?.currentTarget?.id;

    this.setState({ open: true, pressedIcon: targetID }, () => {
      if (targetID === 'REMOVE_TBK') {
        this.fetchAllCategoryTypes((allSystemTypes) => {
          let categoryTypes = categoryTypesPresentOnTournament(
            allSystemTypes || [],
            category_types
          );

          categoryTypes.unshift({
            id: '0',
            name: t('all')
          });

          this.setState({ categoryTypes });
        });
      }
    });
  };

  hideModal = () => {
    if (this.state.isAttemptingToEditModalFields) {
      this.setState({ shouldShowDiscardChanges: true });
    } else {
      this.setState({
        open: false,
        selectedOption: {},
        selectedOptionErrors: {},
        shouldShowDiscardChanges: false,
        isEditing: false
      });
    }
  };

  discardOrKeepEditing = (evt, targetID) => {
    if (targetID === KEEP_EDITING) {
      this.setState({ shouldShowDiscardChanges: false });
    } else if (targetID === CLOSE_DISCARD) {
      this.setState({ isAttemptingToEditModalFields: false }, () =>
        this.hideModal()
      );
    }
  };

  goBack = () => {
    const { navigate, location } = this.props;
    navigate(-1, {
      state: { prevUrl: location.pathname }
    });
  };

  onRemoveTbk = (evt) => {
    const { tournamentData, selectedOption } = this.state;

    this.validateForm(
      evt,
      { type: '' },
      selectedOption,
      'selectedOptionErrors',
      () => {
        this.removeTBK(
          evt,
          tournamentData?.id,
          selectedOption?.type,
          null,
          () => {
            this.setState({ isAttemptingToEditModalFields: false }, () =>
              this.hideModal()
            );
          }
        );
      }
    );
  };

  calculateNumberOfYearsDateChange = (date) => {
    const { errors } = this.state;
    const { t } = this.props;
    const copyErrors = { ...errors };
    let tourDate;

    if (date === null) {
      copyErrors.calc_age_date = t('required');
    } else if (isNaN(Date.parse(date))) {
      copyErrors.calc_age_date = t('invalidDate');
    } else {
      const selectedYear = date.getFullYear();
      const selectedMonth =
        date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
      const selectedDay =
        date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
      tourDate = `${selectedYear}-${selectedMonth}-${selectedDay}`;

      delete copyErrors.calc_age_date;
    }

    this.setState((prevState) => ({
      ...(tourDate
        ? {
            tournamentData: {
              ...prevState.tournamentData,
              calc_age_date: tourDate
            }
          }
        : {}),
      errors: copyErrors
    }));
  };

  onSaveForm = (evt) => {
    const { tournamentData, isSending, selectedCheckboxes } = this.state;
    let newDefaultInputs = { ...defaultInputs };
    const regionVal = document.getElementById('region')?.value;

    if (+tournamentData?.type === 3) {
      delete newDefaultInputs.reg_date;
      delete newDefaultInputs.reg_judge_date;
      delete newDefaultInputs.reg_time;
    }

    this.setState(
      (prevState) => ({
        tournamentData: {
          ...prevState.tournamentData,
          ...(regionVal ? { region: regionVal } : {})
        }
      }),
      () => {
        const form = this.state.tournamentData;

        this.validateForm(evt, newDefaultInputs, form, 'errors', () => {
          form.id
            ? this.updateTournament(evt, form)
            : !isSending && this.addTournament(evt, form);
        });

        if (tournamentData?.id) {
          this.changeTatamisName(
            evt,
            tournamentData.id,
            tournamentData.tatami_set
          );
        }
      }
    );
  };

  fetchRegionBySelectedCountry = (key) => {
    const { tournamentData } = this.state;
    let cpy = { ...tournamentData };
    cpy.region = '';

    this.setState({ tournamentData: cpy });
  };

  selectRadioButton = (evt) => {
    this.setState({ importTourID: evt.target.value });
  };

  render() {
    const {
      countries,
      errors,
      success,
      showModal,
      modalInfo,
      tournamentData,
      selectedCheckboxes,
      isLoading,
      pressedIcon,
      categoryTypes,
      open,
      selectedOption,
      selectedOptionErrors,
      shouldShowDiscardChanges,
      tatamisTheme,
      allRegions,
      tournamentsList,
      tournamentToImportSettings
    } = this.state;
    const { state, closeFormHandler, t, classes, specificDetails } = this.props;
    const { viewportWidth } = this.context;
    const hasDesktopSize = isDesktopView(viewportWidth);

    let onClick, dialogTitle, dialogContent, buttonPurpose, classNameBtn;

    switch (pressedIcon) {
      case 'REMOVE_TBK': {
        onClick = (evt) => this.onRemoveTbk(evt);
        dialogTitle = t('deleteEventGridMsg');
        buttonPurpose = t('remove');
        classNameBtn = classes.marginLeftAuto;
        break;
      }
      case 'IMPORT_SETTINGS': {
        onClick = (evt) =>
          this.importSettings(evt, this.state.importTourID, tournamentData.id);
        dialogTitle = t('importSettings');
        buttonPurpose = t('import');
        classNameBtn = classes.marginLeftAuto;
        dialogContent = (
          <>
            <span
              className={clsx(classes.modalInnerContentHeader, classes.weight)}>
              <span>{t('name')}</span>
              <span>{t('date')}</span>
            </span>
            <RadioBtn
              options={tournamentsList}
              item="id"
              value={this.state.importTourID || ''}
              onClick={(evt) => this.selectRadioButton(evt)}
              specificDetails={{
                labelElem: (el) => (
                  <span className={classes.modalInnerContentBody}>
                    <span>{el.tournament_name}</span>
                    <span>{DDMMYYYY(el.start_date)}</span>
                  </span>
                ),
                className: {
                  rootLabel: classes.labelsForRadioButton,
                  radioIcon: classes.radio,
                  radioGroup: classes.importContainer
                }
              }}
            />
          </>
        );
      }
    }

    return (
      <>
        <SideModal
          closeModal={this.closeModalHandler}
          {...{ success }}
          show={showModal}
          info={modalInfo}
        />
        <Modal
          {...{ open }}
          close={this.hideModal}
          {...{ onClick }}
          {...{ dialogTitle }}
          {...{ dialogContent }}
          {...{ buttonPurpose }}
          {...{ shouldShowDiscardChanges }}
          discardOrKeepEditing={this.discardOrKeepEditing}
          {...{ classNameBtn }}
          specificDetails={{
            className: {
              ...(hasDesktopSize ? { paper: classes.modalWidth } : {})
            }
          }}>
          {pressedIcon === 'REMOVE_TBK' && (
            <Filter
              options={categoryTypes}
              value={
                selectedValue(
                  categoryTypes,
                  ID,
                  selectedOption?.[TYPE],
                  true
                ) || ''
              }
              onChange={(evt, val) =>
                this.selectOption(
                  evt,
                  val,
                  'selectedOption',
                  TYPE,
                  ID,
                  selectedOptionErrors,
                  null,
                  true
                )
              }
              helperText={selectedOptionErrors?.type}
              error={selectedOptionErrors?.type?.length > 0}
              label={t('type')}
              item="name"
              variant="outlined"
            />
          )}
        </Modal>
        {isLoading ? (
          <LoadingState />
        ) : (
          <>
            <TournamentForm
              tournamentData={tournamentData}
              handleTextChange={this.textChangeHandler}
              {...{ countries }}
              tournamentDateChange={this.tournamentDateChangeHandler}
              registrationDateChange={this.registrationDateChangeHandler}
              tournamentTimeChange={this.tournamentTimeChangeHandler}
              calculateNumberOfYearsDateChange={
                this.calculateNumberOfYearsDateChange
              }
              {...{ errors }}
              imageChange={(evt) =>
                this.changeImage(
                  evt,
                  'tournamentData',
                  TOURN_BANNER,
                  'imagePreview'
                )
              }
              values={tournamentData}
              updateAble={tournamentData.id}
              saveForm={(evt) => this.onSaveForm(evt)}
              selectOption={this.selectOption}
              {...{ selectedCheckboxes }}
              selectCheckbox={this.selectCheckbox}
              {...{ state }}
              {...{ closeFormHandler }}
              goBack={this.goBack}
              showModal={this.showModal}
              {...{ tatamisTheme }}
              fetchRegionBySelectedCountry={this.fetchRegionBySelectedCountry}
              {...{ allRegions }}
              {...{ tournamentsList }}
              {...{ tournamentToImportSettings }}
              {...{ viewportWidth, specificDetails }}
            />
          </>
        )}
      </>
    );
  }
}
export default withTranslation()(withStyles(styles)(withRouter(AddTournament)));
