import React, { useState, useRef, useEffect } from 'react';
import {
  Modal,
  Box,
  IconButton,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemText,
  Badge
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as ChatIcon } from '../../assets/img/ChatIconNew.svg';
import { ReactComponent as SendIcon } from '../../assets/img/sendchatbotbtn.svg';
import { ReactComponent as ArrowReplyIcon } from '../../assets/img/ArrowreplyLeft.svg';
import { useChatbotStyles } from './ChatbotStyles';
import { useTranslation } from 'react-i18next';
import {
  calculateUnreadCount,
  truncateMessage,
  combineAndSortMessages,
  handleSendMessage,
  fetchMessagesAndResponses
} from '../../helpers/util';

const ChatbotModal = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [consultantResponses, setConsultantResponses] = useState([]);
  const [sortedMessages, setSortedMessages] = useState([]);
  const [fetchedMessageIds, setFetchedMessageIds] = useState(new Set());
  const [replyToId, setReplyToId] = useState(null);
  const classes = useChatbotStyles();
  const messageListRef = useRef(null);
  const replyRefs = useRef({});
  const prevMessagesLength = useRef(0);
  const { t } = useTranslation();
  const supportOn = JSON.parse(localStorage.getItem('support_on'));
  const [unreadCount, setUnreadCount] = useState(0);
  const [chatOpenTime, setChatOpenTime] = useState(null);

  useEffect(() => {
    if (supportOn) {
      setOpen(false);
      fetchMessagesAndResponses(
        null,
        setMessages,
        setConsultantResponses,
        setFetchedMessageIds,
        true
      );
    }
  }, [supportOn]);
  const handleOpen = () => {
    setOpen(true);
    setUnreadCount(0);

    const currentTime = formatDateTime(new Date());
    setChatOpenTime(currentTime);
    localStorage.setItem('chatOpenTime', currentTime);
    setTimeout(() => {
      scrollToBottom();
    }, 0);
  };
  const handleClose = () => setOpen(false);

  useEffect(() => {
    const storedOpenTime = localStorage.getItem('chatOpenTime');
    if (storedOpenTime) {
      setChatOpenTime(storedOpenTime);
    } else {
    }
  }, []);

  useEffect(() => {
    if (chatOpenTime) {
      const count = calculateUnreadCount(
        messages,
        consultantResponses,
        chatOpenTime
      );
      setUnreadCount(count);
    }
  }, [messages, chatOpenTime]);

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  useEffect(() => {
    const sorted = combineAndSortMessages(messages, consultantResponses);
    setSortedMessages(sorted);
  }, [messages, consultantResponses]);

  const scrollToBottom = () => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    const currentMessagesLength = sortedMessages.length;
    if (currentMessagesLength > prevMessagesLength.current) {
      scrollToBottom();
    }
    prevMessagesLength.current = currentMessagesLength;
  }, [sortedMessages]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchMessagesAndResponses(
        null,
        setMessages,
        setConsultantResponses,
        setFetchedMessageIds
      );
    }, 3000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const formatDateTime = (dateStr) => {
    const date = new Date(dateStr);
    if (isNaN(date.getTime())) {
      return 'Invalid date';
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  useEffect(() => {
    if (chatOpenTime) {
      const count = calculateUnreadCount(
        messages,
        consultantResponses,
        chatOpenTime
      );
      setUnreadCount(count);
    }
  }, [messages, chatOpenTime]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      if (localStorage.getItem('role') === 'administrator' && !replyToId) {
        console.error(
          'Консультант может отправлять сообщения только в ответ на сообщения пользователя.'
        );
        return;
      }
      handleSendMessage(
        message,
        setMessages,
        () => {
          setMessage('');
          setReplyToId(null);
        },
        fetchMessagesAndResponses,
        replyToId
      );
    }
  };

  const handleReplyBoxClick = (replyToId) => {
    if (replyRefs.current[replyToId]) {
      replyRefs.current[replyToId].scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  };

  if (!supportOn) {
    return null;
  }

  return (
    <div>
      <button className={classes.chatbotButton} onClick={handleOpen}>
        <Badge
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          badgeContent={unreadCount > 0 ? unreadCount : null}
          color="secondary">
          <ChatIcon />
        </Badge>
      </button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="chatbot-modal-title"
        aria-describedby="chatbot-modal-description"
        disableScrollLock={true}
        BackdropProps={{
          className: classes.backdrop
        }}>
        <Box className={classes.chatbotModal}>
          <Box
            className={classes.lineup}
            sx={{
              padding: 1
            }}>
            <Box className={classes.lineupContent}>
              <Typography
                variant="h6"
                component="h2"
                className={classes.lineuptext}>
                {t('sendchatMessage')}
              </Typography>
              <Typography variant="h7" className={classes.historyTime}>
                {t('historyTime')}
              </Typography>
            </Box>
            <IconButton onClick={handleClose} className={classes.exitBtn}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box className={classes.content}>
            <List className={classes.messageList} ref={messageListRef}>
              {sortedMessages.map((item) => {
                const isAdmin =
                  localStorage.getItem('role') === 'administrator';
                const flexDirectionStyleAdmin = isAdmin ? 'row-reverse' : 'row';
                const flexDirectionStyleUser = isAdmin ? 'row' : 'row-reverse';
                if (item.type === 1 && item.message) {
                  return (
                    <ListItem
                      key={item.message_id}
                      className={classes.userMessage}
                      style={{
                        flexDirection: flexDirectionStyleUser,
                        display: 'flex'
                      }}
                      ref={(el) => {
                        if (el) replyRefs.current[item.message_id] = el;
                      }}
                      onDoubleClick={() => {
                        setReplyToId(item.message_id);
                      }}>
                      <Box className={classes.messageBox}>
                        <Typography
                          variant="body2"
                          className={classes.userName}>
                          {item.name}
                        </Typography>
                        <ListItemText
                          sx={{
                            marginTop: 0,
                            marginBottom: 0
                          }}
                          primary={`${item.message}`}
                          secondary={
                            <span className={classes.timestamp}>
                              {`${item.timestamp}`}
                            </span>
                          }
                        />
                      </Box>
                    </ListItem>
                  );
                }
                if (item.type === 2) {
                  return (
                    <ListItem
                      key={item.id}
                      className={classes.consultantResponse}
                      style={{
                        flexDirection: flexDirectionStyleAdmin,
                        display: 'flex'
                      }}>
                      <Box className={classes.messageBox}>
                        <Typography
                          variant="body2"
                          className={classes.consultantName}>
                          {t('constantName')}
                        </Typography>
                        <Box className={classes.replyConteiner}>
                          <Box className={classes.replyIndicator}>
                            <ArrowReplyIcon className={classes.arrowIcon} />
                          </Box>
                          <Typography
                            onClick={() =>
                              handleReplyBoxClick(item.reply_to_id)
                            }
                            variant="body2"
                            className={classes.userReply}
                            component="div">
                            {` ${truncateMessage(
                              messages.find(
                                (message) =>
                                  message.message_id === item.reply_to_id
                              )?.message || t('messagenoFound')
                            )}`}
                          </Typography>
                        </Box>
                        <ListItemText
                          sx={{
                            marginTop: 3,
                            marginBottom: 0
                          }}
                          primary={`${item.message}`}
                          secondary={
                            <span className={classes.timestamp}>
                              {`${item.timestamp}`}
                            </span>
                          }
                        />
                      </Box>
                    </ListItem>
                  );
                }
                return null;
              })}
            </List>

            {replyToId && (
              <Box className={classes.replyInfo}>
                <Typography variant="body2" className={classes.replyText}>
                  {`${t('replyTo')} ${truncateMessage(
                    messages.find((msg) => msg.message_id === replyToId)
                      ?.message
                  )}`}
                </Typography>
                <IconButton
                  className={classes.closeReply}
                  onClick={() => setReplyToId(null)}
                  size="100px">
                  <CloseIcon />
                </IconButton>
              </Box>
            )}

            <Box className={classes.inputContainer}>
              <TextField
                fullWidth
                multiline
                rows={2}
                variant="outlined"
                placeholder={t('userWrite')}
                value={message}
                inputProps={{ maxLength: 250 }}
                onChange={handleMessageChange}
                onKeyPress={handleKeyPress}
                className={classes.textField}
              />
              <IconButton
                className={classes.sendButton}
                onClick={() => {
                  if (
                    localStorage.getItem('role') === 'administrator' &&
                    !replyToId
                  ) {
                    console.error(
                      'Консультант может отправлять сообщения только в ответ на сообщения пользователя.'
                    );
                    return;
                  }
                  handleSendMessage(
                    message,
                    setMessages,
                    () => {
                      setMessage('');
                      setReplyToId(null);
                    },
                    fetchMessagesAndResponses,
                    replyToId
                  );
                }}>
                <SendIcon width="26" height="26" />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default ChatbotModal;
