import { makeStyles } from '@material-ui/styles';

export const useStyle = makeStyles((theme) => ({
  fullWidth: {
    width: '100%'
  },

  paper: {
    backgroundColor: '#fff',
    marginBottom: '40px',
    padding: '1.5rem',
    borderRadius: '5px'
  },

  flex: {
    display: 'flex'
  },

  img: {
    gridArea: 'img',
    display: 'grid',
    '@media (min-width:1024px)': {
      width: '300px',
      height: '240px'
    },
    '@media (max-width:1023px)': {
      width: 'auto',
      height: 'auto'
    },
    '@media (max-width: 767px)': {
      justifySelf: 'center',
      marginBottom: '1.5rem',
      width: '270px',
      height: 'auto'
    }
  },

  asterisk: {
    '& .MuiFormLabel-asterisk': {
      color: '#005FEE'
    }
  },

  tameshiwariInput: {
    '&.MuiFormControl-root': {
      width: '45px'
    }
  },

  marginTop: {
    marginTop: '.65em'
  },

  overlappingLabel: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    right: '40px',
    lineHeight: 'unset !important',
    color: '#8996AC'
  },

  labelShrinked: {
    right: 'unset'
  },

  tameshiwari: {
    alignItems: 'flex-start',
    width: '100%'
  },

  marginRight0: {
    margin: 0
  },

  disabled: {
    '&.Mui-disabled': {
      backgroundColor: theme.palette.primary.disabled,
      color: theme.palette.primary.contrastText
    }
  },

  addWrapper: {
    height: '3em',
    width: '3.5em',
    margin: '0.4em 0 0 0.5em'
  },

  grid: {
    display: 'grid'
  },

  paddingLeft2: {
    paddingLeft: '2rem'
  },

  gridMainTournInputs: {
    width: '100%',
    '@media (min-width:1025px)': {
      gridTemplateColumns: '300px 1fr 1fr 1fr 1fr',
      gridTemplateAreas: `
          'img tournament_name tournament_name tournament_name tournament_name'
          'img startEvent time duration_days .'
          'img address address address address'
          '. description description description description'
      `,
      gridGap: '1rem'
    },
    '@media (min-width: 768px) and (max-width: 1024px)': {
      gridTemplateColumns: '1fr 1fr',
      gridTemplateAreas: `
        'img tournament_name'
        'img address'
        'img startEvent'
        'img time'
        'img duration_days'
        'description description'
      `,
      gridGap: '1em'
    },
    '@media (max-width: 767px)': {
      gridTemplateColumns: '1fr',
      gridGap: '1em',
      gridTemplateAreas: `
        'img'
        'img'
        'tournament_name'
        'address'
        'startEvent'
        'time'
        'duration_days'
        'description'
      `
    }
  },

  gridCommission: {
    width: '100%',
    gridGap: '1em',
    '@media (min-width:1025px)': {
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      gridTemplateAreas: `
        'endReg reg_judge_date regDate reg_time'
        'reg_address reg_address reg_address reg_address'
      `
    },
    '@media (min-width: 768px) and (max-width: 1024px)': {
      gridTemplateColumns: '1fr 1fr',
      gridTemplateAreas: `
        'endReg reg_judge_date'
        'regDate reg_time'
        'reg_address reg_address'
      `
    },
    '@media (max-width: 767px)': {
      gridTemplateColumns: '1fr',
      gridTemplateAreas: `
        'endReg'
        'reg_judge_date'
        'regDate'
        'reg_time'
        'reg_address'
        'reg_address'
      `
    }
  },

  gridСommittee: {
    width: '100%',
    gridGap: '1em',
    '@media (min-width:1025px)': {
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      gridTemplateAreas: `
        'main_judge secretary chairman .'
        'org_phone org_email org_address org_address'
      `
    },
    '@media (min-width: 768px) and (max-width: 1024px)': {
      gridTemplateColumns: '1fr 1fr',
      gridTemplateAreas: `
        'main_judge secretary'
        'chairman .'
        'org_phone org_email'
        'org_address org_address'
      `
    },
    '@media (max-width: 767px)': {
      gridTemplateColumns: '1fr',
      gridTemplateAreas: `
        'main_judge'
        'secretary'
        'chairman'
        'org_phone'
        'org_email'
        'org_address'
      `
    }
  },

  gridGeneralSettings: {
    width: '100%',
    '@media (min-width:1025px)': {
      gridTemplateColumns: '1fr 1fr 1fr',
      gridGap: '1rem'
    },
    '@media (min-width: 768px) and (max-width: 1024px)': {
      gridTemplateColumns: '1fr 1fr',
      gridGap: '0.75em'
    },
    '@media (max-width: 767px)': {
      gridTemplateColumns: '1fr',
      gridGap: '0.75em'
    }
  },

  gridColorSettings: {
    width: '100%',
    '@media (min-width:1025px)': {
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      gridGap: '1rem'
    },
    '@media (min-width: 768px) and (max-width: 1024px)': {
      gridTemplateColumns: '1fr 1fr',
      gridGap: '0.75em'
    },
    '@media (max-width: 767px)': {
      gridTemplateColumns: '1fr',
      gridGap: '0.75em'
    }
  },

  gridButtons: {
    width: '100%',
    gridGap: '1rem',
    '@media (min-width:1025px)': {
      gridTemplateColumns: 'repeat(4, 1fr)',
      gridTemplateAreas: `
        '. import remove save'
      `
    },
    '@media (min-width: 768px) and (max-width: 1024px)': {
      gridTemplateColumns: '1fr 1fr',
      gridTemplateAreas: `
        'save import'
        'close remove'
      `
    },
    '@media (max-width: 767px)': {
      gridTemplateColumns: '1fr',
      gridTemplateAreas: `
        'import'
        'remove'
        'save'
      `
    }
  },

  gridCategoriesSettings: {
    width: '100%',
    gridGap: '1rem',
    '@media (min-width:1025px)': {
      gridTemplateColumns: 'repeat(4, 1fr)',
      gridTemplateAreas: `
        'bronze_fight_enabled tameshiwari places_number kata_final'
        'circle_round use_alt_category_name . .'
      `
    },
    '@media (min-width: 768px) and (max-width: 1024px)': {
      gridTemplateColumns: '1fr 1fr',
      gridTemplateAreas: `
        'bronze_fight_enabled circle_round'
        'tameshiwari use_alt_category_name'
        'places_number kata_final'    
      `
    },
    '@media (max-width: 767px)': {
      gridTemplateColumns: '1fr',
      gridTemplateAreas: `
        'bronze_fight_enabled'
        'circle_round'
        'tameshiwari'
        'use_alt_category_name'
        'places_number'
        'kata_final'    
      `
    }
  },

  blockname: {
    padding: '0.5rem 1rem',
    backgroundColor: '#E9ECF6',
    border: '1px solid rgba(137, 150, 172, 0.7)',
    fontWeight: 600,
    fontSize: '18px',
    margin: '16px 0',
    borderRadius: '5px',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },

  arrowDropUp: {
    transform: 'rotate(180deg)'
  },

  arrow: {
    backgroundColor: '#FFFFFF',
    color: '#8996AC'
  },

  tooltip: {
    margin: '0.8rem 0 0.8rem 0',
    fontSize: '16px'
  },

  marginLeft14: {
    marginLeft: '14.375rem'
  },

  alignItems: {
    alignItems: 'center'
  },

  center: {
    '@media (max-width: 767px)': {
      textAlign: 'center'
    }
  },

  marginLeft1: {
    marginLeft: '1rem'
  },

  marginBottom5: {
    marginBottom: '5px'
  },

  marginTop2: {
    marginTop: '2rem'
  },

  marginLeft294: {
    '@media (min-width:1025px)': {
      marginLeft: '316px'
    }
  },

  marginLeft05: {
    marginLeft: '0.5em'
  }
}));

export const styleFilter = {
  width: '100%'
};
